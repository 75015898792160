import React, { useEffect, useMemo } from "react";
import { Dimension } from "@models/IDimension";
import { IParams } from "@models/IParams";
import { useParams } from "react-router-dom";
import { Button, CardState, DataGrid, Heading, Icon, Loading } from "@foris/avocado-ui";
import { Header } from "@common/components";
import { LinkSelector } from "@modules/shared/selectors/Selectors";
import { Link } from "@models/ISchema";
import { getColumnCell, getLinkCell } from "@dimensions/utils/tableUtils";
import Layout from "@common/layout/Layout";
import useGetDimension from "@dimensions/hooks/useGetDimension";
import useGetPackageSection from "../hooks/useGetPackageSection";
import PackageHeaderSubtitle from "../components/PackageHeaderSubtitle";
import useAssignLinks, { ErrorMessagesByType } from "../hooks/useAssignLinks";
import useLinksState from "../hooks/useLinksState";
import LinkAssignmentErrors, { isErrorMessage } from "../components/LinkAssignmentErrors";
import css from "./packageLinkManagement.module.scss";

const headers = {
  link: "Liga",
  campus: "Sede",
  term: "Período",
  groups: "Grupos",
  course: "Asignatura",
  vacancies: "Vacantes",
};

const singularInfoMessage =
  "La liga agregada tiene más de un grupo asociado. Visualiza el detalle de grupos asociados por liga en la tabla inferior.";
const pluralInfoMessage =
  "Las ligas agregadas tienen más de un grupo asociado. Visualiza el detalle de grupos asociados por liga en la tabla inferior.";

const getLeftActionColumn = (
  id: string,
  linksToAdd = {},
  linksToDelete = {},
  errorMessagesByType: ErrorMessagesByType[] = [],
) => {
  const hasError = errorMessagesByType.some(err => Object.keys(err.links).includes(id));

  if (hasError) {
    return <Icon className={css.alert_icon} icon="circle-full-alert" size={16} />;
  }

  if (id in linksToDelete) {
    return <Icon className={css.trashIcon} icon="trash" size={16} />;
  }

  if (id in linksToAdd) {
    return <Icon className={css.addIcon} icon="circle-full-plus" size={16} />;
  }

  return "";
};

const getTableColumns = (
  workspace = "",
  scenario = "",
  origin = "",
  linksToAdd = {},
  linksToDelete = {},
) => {
  return [
    {
      header: headers.link,
      renderer: ({ code, id }: Link) => (
        <div className={css.cellWithIcon}>
          {getLeftActionColumn(id, linksToAdd, linksToDelete)}
          {getLinkCell(`/scheduler/editor/link/${workspace}/${scenario}/${origin}`, id, code)}
        </div>
      ),
      styles: { desktop: { minWidth: "176px", maxWidth: "176px" } },
    },
    {
      header: headers.campus,
      renderer: ({ course }: Link) => getColumnCell(course?.curriculum?.program?.campus?.code),
      styles: { desktop: { minWidth: "70px", maxWidth: "82px" } },
    },
    {
      header: headers.term,
      renderer: ({ bundle }: Link) => getColumnCell(bundle?.term?.name),
      styles: { desktop: { minWidth: "70px", maxWidth: "80px" } },
    },
    {
      header: headers.groups,
      renderer: ({ groups }: Link) => getColumnCell(groups?.map(({ code }) => code).join(", ")),
      styles: { desktop: { minWidth: "200px", maxWidth: "212px" } },
    },
    {
      header: headers.course,
      renderer: ({ course }: Link) => getColumnCell(course?.name),
      styles: { desktop: { minWidth: "272px", maxWidth: "284px" } },
    },
    {
      header: headers.vacancies,
      renderer: ({ vacancies }: Link) => getColumnCell(vacancies),
      styles: { desktop: { minWidth: "72px", maxWidth: "84px" } },
    },
  ];
};

const PackageLinkManagement = () => {
  const { id, origin, scenario, workspace }: IParams = useParams();
  const [dimension] = useGetDimension(Dimension.package);
  const {
    linksToAdd,
    linksToDelete,
    assignmentIds,
    deletionsIds,
    assignmentsWithMultipleGroups,
    handleResetChanges,
    setLinksToAdd,
    setLinksToDelete,
  } = useLinksState({
    packageId: id,
  });

  const { isLoading, headerData, packageData, loadPackageData } = useGetPackageSection({
    resourceId: id,
    scenarioId: scenario,
    originId: origin,
    workspaceId: workspace,
    fetchWithlinks: true,
  });

  const { handleLinkAssignment, isLoading: isLoadingChanges, errorMessagesByType } = useAssignLinks(
    {
      scenarioId: scenario,
      originId: origin,
      assignments: assignmentIds,
      deletions: deletionsIds,
      onSavedChanges: () => {
        loadPackageData();
        handleResetChanges();
      },
    },
  );

  const isEditionEnabled = !packageData?.isPublished;

  const hasErrors = useMemo(() => {
    return errorMessagesByType.some(err => isErrorMessage(err.type, err.userCanSkipValidations));
  }, [errorMessagesByType]);

  useEffect(() => {
    loadPackageData();
  }, []);

  const linksToAvoidInSelector = useMemo(() => {
    return packageData?.links?.map(link => link.id) || [];
  }, [packageData?.links]);

  const packagesLinks = useMemo(() => {
    return [...(packageData?.links || []), ...Object.values(linksToAdd)];
  }, [packageData?.links, linksToDelete, linksToAdd]);

  const headersToAvoidSort = useMemo((): Set<string> => {
    const headersSet = new Set();

    Object.values(headers).forEach(header => {
      headersSet.add(header);
    });

    return headersSet as Set<string>;
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const handleDeleteLink = (link: Link) => {
    if (link?.id in linksToDelete) {
      const newLinksToDelete = structuredClone(linksToDelete);
      delete newLinksToDelete[link.id];

      setLinksToDelete(newLinksToDelete);
      return;
    }

    if (link?.id in linksToAdd) {
      const newLinksToAdd = structuredClone(linksToAdd);
      delete newLinksToAdd[link.id];

      setLinksToAdd(newLinksToAdd);
      return;
    }

    setLinksToDelete({ ...linksToDelete, [link.id]: link });
  };

  const handleSelectLink = (link: Link) => {
    if (!link?.id || !!linksToAdd?.[link.id]) {
      return;
    }

    handleLinkAssignment(true, true, [
      ...assignmentIds,
      {
        linkId: link.id,
        packageId: id,
      },
    ]);
    setLinksToAdd({ ...linksToAdd, [link.id]: link });
  };

  return (
    <Layout contextSearch>
      {isLoadingChanges && <Loading />}

      <Header
        dimension={dimension.label}
        {...(headerData ?? {})}
        subTitle={<PackageHeaderSubtitle packageData={packageData} />}
      />

      <main className={css.packageLinkManagement}>
        <Heading className={css.packageLinkManagement_title} type="h2">
          Gestionar ligas del paquete
        </Heading>

        {isEditionEnabled && (
          <LinkSelector
            className={css.packageLinkManagement_linkSelector}
            placeholder="Agregar liga existente"
            label=""
            onSelectLink={handleSelectLink}
            linksToAvoid={linksToAvoidInSelector}
            keepSelection={false}
            selectedExternalOptions={linksToAdd}
            splitByGroups
          />
        )}

        {assignmentsWithMultipleGroups > 0 && (
          <CardState
            className={css.infoCard}
            typeCard="informative"
            title={`Liga${assignmentsWithMultipleGroups > 1 ? "s" : ""} con Múltiples Grupos`}
          >
            {assignmentsWithMultipleGroups > 1 ? pluralInfoMessage : singularInfoMessage}
          </CardState>
        )}

        <DataGrid
          className={css.packageLinkManagement_table}
          columns={getTableColumns(workspace, scenario, origin, linksToAdd, linksToDelete)}
          batch={packagesLinks}
          onHeaderClick={() => null}
          nonSortableColumns={headersToAvoidSort}
          rightActions={
            isEditionEnabled
              ? [
                  {
                    onClick: handleDeleteLink,
                    isVisible: () => true,
                    icon: () => "circle-full-minus",
                    mobileLabel: "Eliminar",
                    disabled: () => packageData?.isPublished,
                  },
                ]
              : null
          }
        />

        <LinkAssignmentErrors
          origin={origin}
          scenario={scenario}
          workspace={workspace}
          errorMessagesByType={errorMessagesByType}
        />

        {isEditionEnabled && (
          <div className={css.packageLinkManagement_actions}>
            <Button disabled={isLoadingChanges} variant="outline" onClick={handleResetChanges}>
              Cancelar
            </Button>

            <Button
              disabled={
                isLoadingChanges || (!assignmentIds?.length && !deletionsIds?.length) || hasErrors
              }
              onClick={() => handleLinkAssignment(false, false)}
            >
              Guardar cambios
            </Button>
          </div>
        )}
      </main>
    </Layout>
  );
};

export default PackageLinkManagement;
