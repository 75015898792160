import React, { useMemo, useState } from "react";
import { useMutation } from "react-apollo";
import cx from "classnames";
import { useParams, useHistory } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { TimeRange } from "../../../shared/inputs";
import { Days, Assignment } from "../../../shared/checks";
import { EmptyResult } from "../../../shared/modals";
import WeeksRange from "../../../shared/weeksRange/WeeksRange";
import { validations } from "../../../advance-search/utils";
import {
  IAvailability,
  ITimeRangeDependency,
  IWeeksRangeDependency,
  IAssignment,
} from "../../../advance-search/IAdvanceSearch";
import { AssignmentFilterInput } from "../../../../models/ISchema";
import Button from "../../../../common/components/Button/Button";
import { Loading } from "@foris/avocado-ui";
import { objectEmpty } from "../../../../utils/validations";
import { sectionSearchMutation } from "../graphql/advanceSearch.queries";
import AdvancedSelectorsWrapper from "@modules/advance-search/components/advanced-selectors-wrapper";
import css from "../../../advance-search/advance-search.module.scss";

const AdvanceSearchSection: React.FC<any> = (props: any) => {
  const history = useHistory();
  const { origin, scenario, workspace }: IParams = useParams();
  const [advanceSearchMutation] = useMutation(sectionSearchMutation, {});
  const [daysError, setDaysError] = useState(false);
  const [error, setError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [timeRangeError, setTimeRangeError] = useState(false);
  const [objAvailability, setObjAvailability] = useState<IAvailability>({});
  const [objAssignment, setObjAssignment] = useState<AssignmentFilterInput>({});
  const [loading, setLoading] = useState(false);

  const [selectorsState, setSelectorsState] = useState({
    campus: null,
    department: null,
    modality: null,
    shift: null,
    program: null,
    curriculum: null,
    level: null,
    course: null,
  });

  const hasSomeFilter = useMemo(() => {
    return (
      !objectEmpty(objAvailability) ||
      !objectEmpty(objAssignment) ||
      Object.values(selectorsState).some(value => !!value)
    );
  }, [objAvailability, objAssignment, selectorsState]);

  const setDays = (value: number[]) => {
    const validation = validations.days(value, objAvailability);
    setObjAvailability(validation.availability);
    setError(validation.error);
    setTimeRangeError(validation.timeRangeError);
    setDaysError(validation.daysError);
  };

  const setTimeRange = (value: ITimeRangeDependency, error: boolean) => {
    if (error) {
      setError(true);
      return;
    }
    const validation = validations.timeRange(value, objAvailability);
    setObjAvailability(validation.availability);
    setError(validation.error);
    setTimeRangeError(validation.timeRangeError);
    setDaysError(validation.daysError);
  };

  const setWeeksRange = (values: IWeeksRangeDependency) => {
    const validation = validations.weeksRange(values, objAvailability);
    setObjAvailability(validation.availability);
  };

  const setAssignmentChecks = (values: IAssignment) => {
    const validation = validations.assignmentChecks(values, objAssignment);
    setObjAssignment(validation);
  };

  const createObjectMutation = () => {
    const filterArray = [];
    const programFilters = [];

    !objectEmpty(objAvailability) && filterArray.push(objAvailability);
    !objectEmpty(objAssignment) && filterArray.push({ assignment: { ...objAssignment } });

    selectorsState.level && filterArray.push({ courseLevel: { eq: selectorsState.level } });
    selectorsState.curriculum &&
      filterArray.push({ curriculumId: { eq: selectorsState.curriculum } });
    selectorsState.course && filterArray.push({ courseId: { eq: selectorsState.course } });
    selectorsState.campus && programFilters.push({ campusId: { eq: selectorsState.campus } });
    selectorsState.modality && programFilters.push({ modalityId: { eq: selectorsState.modality } });
    selectorsState.department &&
      programFilters.push({ departmentId: { eq: selectorsState.department } });
    selectorsState.shift && programFilters.push({ shiftId: { eq: selectorsState.shift } });
    selectorsState.program && programFilters.push({ id: { eq: selectorsState.program } });

    if (programFilters.length > 0) {
      filterArray.push({
        program: {
          and: programFilters,
        },
      });
    }

    const mutationFilter = {
      originId: origin,
      scenarioId: scenario,
      filter: {
        fields: {
          and: [...filterArray],
        },
      },
      pagination: {
        page: 1,
        size: 10,
      },
    };
    return mutationFilter;
  };
  const callUpdateHook = async () => {
    setLoading(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      setLoading(false);
      const dataItems = data.filter.createSectionFilter;
      if (dataItems.sections.items.length > 0) {
        const idSearch = dataItems.id;
        const contextUrl = `${workspace}/${scenario}/${origin}`;
        history.push(`/scheduler/editor/searchBy/section/${contextUrl}/?advance=${idSearch}`);
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const advanceRowContent = (title: string, component: React.ReactNode) => (
    <div className={cx(css.advance_item, "container-row")}>
      <h5 className={cx(css.advance_item_title, "col_12")}>{title}</h5>
      <div className={cx(css.advance_row, "container-row")}>{component}</div>
    </div>
  );

  let weeksDisabled = true;
  if (objAvailability.availability) {
    const daysExist = objAvailability.availability.hasOwnProperty("dayId");
    const rangeExist = objAvailability.availability.hasOwnProperty("blockRange");
    if (rangeExist) {
      const start = objAvailability.availability.blockRange.hasOwnProperty("start");
      const end = objAvailability.availability.blockRange.hasOwnProperty("end");
      if (start && end && daysExist) weeksDisabled = false;
    }
  }

  return (
    <>
      <EmptyResult show={isEmpty} onClose={() => setIsEmpty(false)} />

      <AdvancedSelectorsWrapper
        className={css.hasBottomSeparation}
        scenario={scenario}
        onChangeState={setSelectorsState}
      />

      {advanceRowContent(
        "Días",
        <Days required={daysError} onCallback={values => setDays(values)} />,
      )}
      {advanceRowContent(
        "Rango de horario",
        <TimeRange
          required={timeRangeError}
          onCallback={(values, error) => setTimeRange(values, error)}
        />,
      )}

      <WeeksRange {...props} disabled={weeksDisabled} onCallback={value => setWeeksRange(value)} />

      {advanceRowContent(
        "Asignación",
        <Assignment onCallback={value => setAssignmentChecks(value)} />,
      )}

      {loading ? (
        <div className="loading_wrapper">
          <Loading />
        </div>
      ) : (
        <Button
          className={css.actionButton}
          disabled={error || !hasSomeFilter}
          onClick={() => callUpdateHook()}
        >
          Buscar
        </Button>
      )}
    </>
  );
};

export default AdvanceSearchSection;
