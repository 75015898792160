import React, { useMemo, useState } from "react";
import { useMutation } from "react-apollo";
import cx from "classnames";
import { useParams, useHistory } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { ComponentType } from "../../../shared/selectors";
import { EmptyResult } from "../../../shared/modals";
import { validations } from "../../../advance-search/utils";
import { IComponentTypeId } from "../../../advance-search/IAdvanceSearch";
import Button from "../../../../common/components/Button/Button";
import { Loading } from "@foris/avocado-ui";
import { objectEmpty } from "../../../../utils/validations";
import { courseSearchMutation } from "../graphql/advanceSearch.queries";
import AdvancedSelectorsWrapper from "@modules/advance-search/components/advanced-selectors-wrapper";
import css from "../../../advance-search/advance-search.module.scss";

const AdvanceSearchCourse: React.FC<any> = () => {
  const history = useHistory();
  const { origin, scenario, workspace }: IParams = useParams();
  const [advanceSearchMutation] = useMutation(courseSearchMutation, {});
  const [isEmpty, setIsEmpty] = useState(false);
  const [objComponentType, setObjComponentType] = useState<IComponentTypeId>({});
  const [loading, setLoading] = useState(false);

  const [selectorsState, setSelectorsState] = useState({
    campus: null,
    department: null,
    modality: null,
    shift: null,
    program: null,
    curriculum: null,
    level: null,
  });

  const hasSomeFilters = useMemo(() => {
    return Object.values(selectorsState).some(value => !!value) || !objectEmpty(objComponentType);
  }, [selectorsState, objComponentType]);

  const setComponentType = (value: string) => {
    const validation = validations.componentType(value, objComponentType);
    setObjComponentType(validation);
  };

  const createObjectMutation = () => {
    const filterArray = [];
    const programFilters = [];

    !objectEmpty(objComponentType) && filterArray.push(objComponentType);

    selectorsState.level && filterArray.push({ courseLevel: { eq: selectorsState.level } });
    selectorsState.curriculum &&
      filterArray.push({ curriculumId: { eq: selectorsState.curriculum } });
    selectorsState.campus && programFilters.push({ campusId: { eq: selectorsState.campus } });
    selectorsState.modality && programFilters.push({ modalityId: { eq: selectorsState.modality } });
    selectorsState.department &&
      programFilters.push({ departmentId: { eq: selectorsState.department } });
    selectorsState.shift && programFilters.push({ shiftId: { eq: selectorsState.shift } });
    selectorsState.program && programFilters.push({ id: { eq: selectorsState.program } });

    if (programFilters.length > 0) {
      filterArray.push({
        program: {
          and: programFilters,
        },
      });
    }
    const mutationFilter = {
      originId: origin,
      scenarioId: scenario,
      filter: {
        fields: {
          and: [
            // Set default filter 'cause now we bring only the ones with assignment
            { hasEvents: true },
            ...filterArray,
          ],
        },
        pagination: {
          page: 1,
          size: 10,
        },
      },
    };
    return mutationFilter;
  };
  const callUpdateHook = async () => {
    setLoading(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      setLoading(false);
      const dataItems = data.filter.createCourseFilter;
      if (dataItems.courses.items.length > 0) {
        const idSearch = dataItems.id;
        const contextUrl = `${workspace}/${scenario}/${origin}`;
        history.push(`/scheduler/editor/searchBy/course/${contextUrl}/?advance=${idSearch}`);
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const advanceRowContent = (title: string, component: React.ReactNode) => (
    <div className={cx(css.advance_item, "container-row")}>
      <h5 className={cx(css.advance_item_title, "col_12")}>{title}</h5>
      <div className={cx(css.advance_row, "container-row")}>{component}</div>
    </div>
  );

  return (
    <>
      <EmptyResult show={isEmpty} onClose={() => setIsEmpty(false)} />

      <AdvancedSelectorsWrapper
        className={css.hasBottomSeparation}
        scenario={scenario}
        selectors={["campus", "department", "modality", "shift", "program", "curriculum", "level"]}
        onChangeState={setSelectorsState}
      />

      {advanceRowContent(
        "Componente",
        <ComponentType onCallback={value => setComponentType(value?.value)} />,
      )}
      {loading ? (
        <div className="loading_wrapper">
          <Loading />
        </div>
      ) : (
        <Button
          className={css.actionButton}
          onClick={() => callUpdateHook()}
          disabled={!hasSomeFilters}
        >
          Buscar
        </Button>
      )}
    </>
  );
};

export default AdvanceSearchCourse;
