import React, { ReactElement, useRef, useState } from "react";
import { Icon, IconType, Tooltip } from "@foris/avocado-ui";
import cx from "classnames";
import css from "./menu.module.scss";
import useClickOutside from "@common/hooks/useClickOutside";

export interface MenuItem {
  icon?: IconType;
  disabled?: boolean;
  tooltipLabel?: string;
  label?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface BaseMenuProps {
  items?: MenuItem[];
}

interface ButtonWrapperProps {
  tooltipLabel?: string;
  children: ReactElement;
}

const ButtonWrapper = ({ tooltipLabel, children }: ButtonWrapperProps) => {
  if (tooltipLabel) {
    return <Tooltip label={tooltipLabel}>{children}</Tooltip>;
  }

  return children;
};

const Menu = ({ items = [] }: BaseMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const menuRef = useRef(null);
  useClickOutside({ ref: menuRef, onClickOutside: () => setIsOpen(false) });

  return (
    <div ref={menuRef} className={css.headerMenu}>
      <button className={css.btnMenu} onClick={() => setIsOpen(!isOpen)}>
        <Icon className={css.btnMenu_icon} size={24} icon="circle-full-dots" />
      </button>

      {isOpen && (
        <div className={css.cntMenu}>
          {items.map((item, index) => (
            <div key={index}>
              <ButtonWrapper tooltipLabel={item?.tooltipLabel}>
                <button
                  className={cx(
                    css.cntMenu_item,
                    "container-row row_align--center",
                    item?.disabled && css.cntMenu_item__disabled,
                  )}
                  onClick={e => {
                    if (item?.disabled) return;

                    item?.onClick?.(e);
                    setIsOpen(false);
                  }}
                >
                  {item?.icon ? <Icon icon={item.icon} size={14} className={css.iconItem} /> : null}{" "}
                  {item?.label}
                </button>
              </ButtonWrapper>

              {index + 1 < items.length ? <div className={css.cntMenu_divider} /> : null}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Menu;
