import React, { useState, useEffect, memo, useContext } from "react";
import {
  addIndex,
  always,
  ap,
  append,
  assoc,
  head,
  isEmpty,
  join,
  lensProp,
  map,
  pipe,
  propOr,
  reduce,
  set,
  split,
  uniq,
  when,
} from "ramda";
import cx from "classnames";
import RemoveBookingModal from "../RemoveBookingModal/RemoveBookingModal";
import useGetEventTypes from "../../hooks/useGetEventTypes";
import * as utils from "../../utils";
import useClassroomBookingsMutation from "../../hooks/useClassroomBookingsMutation";
import ProgramSelect from "../AsyncSelectors/ProgramAsyncSelect";
import UserSelect from "../AsyncSelectors/UserAsyncSelect";
import css from "./detail.module.scss";
import { IParams } from "@models/IParams";
import { useParams, useHistory } from "react-router-dom";
import { Button, Card, Icon, Select, Input, Textarea, Snackbar } from "@foris/avocado-ui";
import { ClassroomBooking } from "@models/ISchema";
import { BookingContext } from "@modules/booking/context/BookingContext";
import { Types } from "../../context/request.reducer";
import { Types as SearchTypes } from "../../context/search.reducer";
import { DayRowData } from "@modules/booking/context/types";
import { Preferences } from "../../context/types";
import useSelectedWeekIntervals from "@modules/booking/hooks/useSelectedWeekIntervals";
import { getProgramSelectorLabel } from "@modules/booking/utils/programs";
import { ContextApp } from "@config/Context/contextApp";

interface Props {
  booking: ClassroomBooking;
  editing: boolean;
  setEdit: (value: boolean) => void;
  campus: string;
}

interface SelectableOption {
  value: string;
  label: string;
}

const Detail: React.FC<Props> = ({ booking, editing, setEdit, campus }) => {
  const history = useHistory();
  const { workspace, origin, scenario }: IParams = useParams();
  const contextUrl = `${workspace}/${scenario}/${origin}`;
  const { state, dispatch } = useContext(BookingContext);
  const { user } = useContext(ContextApp);
  const [, getBookingTypes] = useGetEventTypes();
  const [bookingTitle, setBookingTitle] = useState<string>(booking?.title);
  const [bookingDescription, setBookingDescription] = useState<string>(booking?.description);
  const [descriptionHasBeenChanged, setDescriptionHasBeenChanged] = useState<boolean>(false);
  const [activeModal, setActiveModal] = useState<boolean>(false);
  const [ignoreMutationResult, setIgnoreMutationResult] = useState<boolean>(false);
  const [mutationResult, submitClassroomBookingsMutation] = useClassroomBookingsMutation({
    origin,
    scenario,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [selectedWeeksInterval] = useSelectedWeekIntervals(state?.search?.currentBooking?.sessions);

  const [selectableBookingTypeByIds, setSelectableBookingTypeByIds] = useState<
    Record<string, SelectableOption>
  >({});

  useEffect(() => {
    if (ignoreMutationResult) {
      return;
    }

    const { result, error } = mutationResult;

    if (isEmpty(result)) return;

    const booking = result?.payload?.updates[0]?.booking;

    if (!error && Boolean(booking)) {
      setIgnoreMutationResult(true);

      // cleaning
      dispatch({ type: SearchTypes.ClearSearch, payload: null });
      dispatch({ type: Types.ClearRequest, payload: null });

      dispatch({ type: SearchTypes.SetCurrentBooking, payload: booking });
      dispatch({ type: SearchTypes.SetView, payload: "search" });
      dispatch({
        type: Types.SetSnackbar,
        payload: { display: true, message: "Detalles actualizados exitosamente", error: false },
      });
      dispatch({
        type: Types.SetUserResponsible,
        payload: {
          value: booking?.responsible?.id,
          label: `${booking?.responsible?.name} ${booking?.responsible?.lastName}`,
          id: booking?.responsible?.id,
          error: false,
        },
      });
      dispatch({ type: Types.SetEventType, payload: { value: booking?.type?.id, error: false } });

      // redirect to the created booking (remember that booking updates
      // deletes the edited booking and creates a new one)
      history.push(`/booking/detail/${contextUrl}/${booking?.id}`);
    } else {
      setIgnoreMutationResult(true);

      dispatch({
        type: Types.SetSnackbar,
        payload: {
          display: true,
          message: "Ocurrió un error. Detalles no actualizados",
          error: true,
        },
      });
    }
  }, [mutationResult]);

  useEffect(() => {
    getBookingTypes();

    dispatch({
      type: Types.SetUserResponsible,
      payload: {
        value: booking?.responsible?.id,
        label: `${booking?.responsible?.name} ${booking?.responsible?.lastName}`,
        id: booking?.responsible?.id,
        error: false,
      },
    });
    dispatch({ type: Types.SetEventType, payload: { value: booking?.type?.id, error: false } });
  }, []);

  useEffect(() => {
    if (!state?.request?.eventTypesOptions?.length) return;
    setSelectableBookingTypeByIds(
      reduce(
        (acc, bookingType: any) =>
          assoc(
            bookingType?.id,
            { value: bookingType?.id, label: bookingType?.label, ...bookingType },
            acc,
          ),
        {},
        state?.request?.eventTypesOptions,
      ),
    );
  }, [state?.request?.eventTypesOptions]);

  /**
   * Update the edited booking after any change
   */
  useEffect(() => {
    dispatch({
      type: Types.SetEditedBooking,
      payload: pipe(
        set(lensProp<any>("title"), bookingTitle),
        set(lensProp("responsible"), state?.request?.userResponsible),
        when<any, any>(
          always(descriptionHasBeenChanged),
          set(lensProp("description"), bookingDescription),
        ),
        set(lensProp("type"), selectableBookingTypeByIds[state?.request?.eventType?.value]),
        set(lensProp("program"), state?.search?.preferences?.program?.value),
      )(state?.request?.editedBooking),
    });
  }, [
    bookingTitle,
    bookingDescription,
    state?.request?.eventType,
    state?.request?.userResponsible,
    state?.search?.preferences?.program,
    state?.search?.preferences?.building,
  ]);
  /**
   * When the user wants to edit a booking, the callback inside this `useEffect`
   * enrich the edition form with the data of the booking to edit.
   */
  useEffect(() => {
    if (!state?.search?.currentBooking) return;

    const formatTime = pipe(split(":"), ([hour, min]) => join(":", [hour, min]));
    const sessions = map(
      pipe(propOr([], "scheduleEvent"), head),
      state?.search?.currentBooking?.sessions ?? [],
    );
    const newDateTime: DayRowData[] = addIndex(reduce)(
      (acc: any, event: any, index: number) => {
        const [[date, rawTime], [, rawEndTime]] = ap(
          [split(" ")],
          [event?.start ?? "", event?.end ?? ""],
        );
        const [startTime, endTime] = ap([formatTime], [rawTime, rawEndTime]);
        return append(
          {
            index,
            recurrence: state?.request?.editedBooking?.isRecurrent,
            day: { error: false, value: date },
            start: { error: false, value: { label: startTime, value: startTime } },
            end: { error: false, value: { label: endTime, value: endTime } },
          },
          acc,
        );
      },
      [],
      sessions,
    );
    dispatch({ type: SearchTypes.SetDateTime, payload: uniq(newDateTime) });

    // set the sessions and responsible of the editedBooking
    dispatch({
      type: Types.SetEditedBooking,
      payload: pipe(
        set(lensProp<ClassroomBooking>("sessions"), state?.search?.currentBooking?.sessions),
        set(lensProp<ClassroomBooking>("responsible"), state?.search?.currentBooking?.responsible),
        set(lensProp<ClassroomBooking>("capacity"), state?.search?.currentBooking?.capacity),
      )(state?.request?.editedBooking),
    });

    // set the selectedWeeks of the current editedBooking
    dispatch({
      type: SearchTypes.SetIntervals,
      payload: map(
        week => set(lensProp("selected"), week?.id in selectedWeeksInterval, week),
        state?.search?.intervals ?? [],
      ),
    });
  }, [state?.search?.currentBooking]);

  useEffect(() => {
    // set building and program on preferences
    const program = booking?.relatedProgram;
    const building = state?.search?.currentBooking?.classroom?.building;

    const payload: Preferences = pipe(
      set(lensProp<any>("building"), { value: building?.id, label: building?.name, ...building }),
      set(lensProp<any>("program"), program),
    )(state?.search?.preferences);

    dispatch({
      type: SearchTypes.SetPreferences,
      payload: payload,
    });
  }, []);

  const handleSaveChanges = async () => {
    const program = state?.search?.preferences?.program?.value;

    if (isLoading) return;
    if (!user?.abilities?.can_skip_program_selection && !program) {
      const clonePreferences = { ...state?.search?.preferences };
      clonePreferences.program = {
        label: null,
        value: null,
        error: true,
      };

      dispatch({
        type: SearchTypes.SetPreferences,
        payload: clonePreferences,
      });
      return;
    }

    setIsLoading(true);
    setIgnoreMutationResult(false);
    await submitClassroomBookingsMutation(state?.request?.editedBooking, false, true, true);
    setIsLoading(false);
  };

  return (
    <Card.Simple className={css.detail}>
      <RemoveBookingModal
        campus={campus}
        open={activeModal}
        onClose={() => {
          setActiveModal(false);
        }}
      />

      <Snackbar
        type={state?.request?.snackbar?.error ? "error" : "confirm"}
        setValueActive={value =>
          dispatch({
            type: Types.SetSnackbar,
            payload: set(lensProp<any>("display"), value, state?.request?.snackbar),
          })
        }
        active={state?.request?.snackbar?.display}
        icon={state?.request?.snackbar?.error ? "circle-error" : "circle-check"}
        duration={3}
      >
        {state?.request?.snackbar?.message}
      </Snackbar>

      <header className={cx(css.header, "container-row", "row--between")}>
        <div>
          <h3 className={cx(css.header__title)}>Detalles del evento</h3>
          <p className={cx(css.header__status, "col_12")}>
            <Icon
              icon={utils.icons.statusIcon(booking?.status)}
              size={12}
              className={cx(css.iconStatusBooking, utils.icons.iconClassName(booking?.status))}
            />
            {utils.icons.iconText(booking?.status)}
          </p>
        </div>

        <button
          className={cx(css.header_delete)}
          disabled={isLoading}
          onClick={() => {
            setActiveModal(true);
          }}
        >
          <Icon icon="trash" size={16} className={css.iconDelete} />
        </button>
      </header>

      {editing ? (
        <>
          <UserSelect className={css.responsable_selector} />
          <Input
            placeholder="Título del evento"
            label="Título de evento"
            value={bookingTitle}
            maxLength={80}
            classname={{ global: cx(css.info_container, "col_4", "col_sm_12") }}
            onChange={(e: any) => setBookingTitle(e?.target?.value)}
          />
          <section className={cx(css.info_container, "container-row")}>
            <Select
              label="Tipo de evento"
              placeholder="Tipo de reserva"
              options={state?.request?.eventTypesOptions || []}
              value={selectableBookingTypeByIds[state?.request?.eventType?.value]}
              className={cx(css.booking_type_selector, "col_4", "col_md_12")}
              onChange={e =>
                dispatch({ type: Types.SetEventType, payload: { value: e?.value, error: false } })
              }
            />
            <ProgramSelect
              className={css.program_selector}
              initialValue={
                booking?.relatedProgram?.id
                  ? {
                      label: getProgramSelectorLabel(booking?.relatedProgram),
                      value: booking?.relatedProgram?.id,
                    }
                  : undefined
              }
            />
          </section>
          <Textarea
            placeholder="..."
            label="Descripción (opcional)"
            max={250}
            value={booking?.description}
            className={cx(css.info_container, "col_12")}
            onChange={(e: any) => {
              setDescriptionHasBeenChanged(true);
              setBookingDescription(e?.target?.value);
            }}
          />

          <section className={cx(css.info_container, "container-row")}>
            <Button
              className={css.info_container__button}
              disabled={isLoading}
              variant={"outline"}
              onClick={() => setEdit(false)}
            >
              Cancelar
            </Button>
            <Button
              disabled={isLoading}
              color={"primary"}
              variant={"solid"}
              onClick={handleSaveChanges}
            >
              Guardar cambios
            </Button>
          </section>
        </>
      ) : (
        <>
          <section className={cx(css.info_container, "container-row")}>
            <div className={cx(css.info, "col_2", "col_sm_6")}>
              <p className={css.info__label}>Responsable</p>
              <p className={css.info__text}>
                {`${booking?.responsible?.name} ${booking?.responsible?.lastName}` || "--"}
              </p>
            </div>
          </section>

          <section className={cx(css.info_container, "container-row")}>
            <div className={cx(css.info, "col_2", "col_sm_6")}>
              <p className={css.info__label}>Tipo</p>
              <p className={css.info__text}>{booking?.type?.name || "--"}</p>
            </div>

            <div className={cx(css.info, "col_4", "col_sm_6")}>
              <p className={css.info__label}>Carrera</p>
              <div className={css.info__text}>
                <p className={css.selector__input__population__label_top}>
                  {booking?.relatedProgram?.code} <b>{booking?.relatedProgram?.name}</b>
                </p>
                <p className={css.selector__input__population__label_bottom}>
                  SE: {booking?.relatedProgram?.campus?.name} | ES:{" "}
                  {booking?.relatedProgram?.department?.name} | MO:{" "}
                  {booking?.relatedProgram?.modality?.name} |JO:{" "}
                  {booking?.relatedProgram?.shift?.name}
                </p>
              </div>
            </div>
          </section>

          <section className={cx(css.info_container, "container-row")}>
            <div className={cx(css.info, "col_2", "col_sm_6")}>
              <p className={css.info__label}>Descripción</p>
              <p className={css.info__text}>{booking?.description || "--"}</p>
            </div>
          </section>

          <Button disabled={false} variant={"outline"} onClick={() => setEdit(true)}>
            Cambiar Detalles
          </Button>
        </>
      )}
    </Card.Simple>
  );
};

export default memo(Detail);
