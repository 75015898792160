import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-apollo";
import cx from "classnames";
import { Input, Button, Checkbox, Loading } from "@foris/avocado-ui";
import {
  CubeMutation,
  GroupValidationErrorUnion,
  GroupPayload,
  Group,
  Course,
} from "../../../models/ISchema";
import { IParams } from "../../../models/IParams";
import { getValidationErrorReason, linkValidation, IObjValidationError } from "../utils";
import { CourseMultiKey } from "../../shared/selectors";
import TermSelect from "./TermSelect";
import { ADD_GROUPS_MUTATION } from "./../graphql/groups.queries";
import { courseMultiKeyQuery } from "./../graphql/courseMultiKey.queries";
import GroupCreate from "../../../models/Errors/GroupCreate";
import { IErrorWarning } from "../../../models/Errors/ErrorWarning";
import { Header } from "../../../common/components";
import { useGetSharedPackComponents } from "../hooks/useGetSharedPackComponents";
import GroupComponentInputs from "./GroupComponentInputs";
import css from "./groups.module.scss";
import {
  AdaptedSharedPackComponent,
  AdaptedSharedPackComponentPayload,
} from "./GroupComponentInputs/GroupComponentInputs";

export interface IAddGroupsProps {
  onClose?: () => void;
  linkId: string;
  bundleId: string | number;
  headerData?: { subTitle: string; title: string };
}

interface IAddGroupReturn {
  idCampus: string;
  idDepartment: string;
  idModality: string;
  idShift: string;
  objLevel: any;
  idProgram: string;
  idCourse: string;
  idTerm: string;
  idCurriculum: string;
}

const emptyObjGroup = {
  idCampus: null,
  idDepartment: null,
  idModality: null,
  idShift: null,
  objLevel: null,
  idProgram: null,
  idCourse: null,
  idTerm: null,
  idCurriculum: null,
};

const AddGroups: React.FC<IAddGroupsProps> = (props: IAddGroupsProps) => {
  const { onClose, linkId, headerData, bundleId } = props;
  const { origin, scenario }: IParams = useParams();
  const [addGroupsMutation] = useMutation(ADD_GROUPS_MUTATION, {});
  const [objGroup, setObjGroup] = useState<IAddGroupReturn>(emptyObjGroup);
  const [idTerm, setIdTerm] = useState(null);
  const [valueCode, setValueCode] = useState(null);
  const [valueCapacity, setValueCapacity] = useState(null);
  const [validationArray, setValidationArray] = useState(null);
  const [validationStandardArray, setvalidationStandardArray] = useState<IErrorWarning[]>([]);
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formatError, setFormatError] = useState(false);
  const [componentsPayload, setComponentsPayload] = useState<AdaptedSharedPackComponent[]>([]);
  const [isComponentsFormValid, setIsComponentsFormValid] = useState(false);

  const { sharedPackComponents, isLoading, getSharedPacks } = useGetSharedPackComponents({
    scenarioId: scenario,
    originId: origin,
  });

  const { data } = useQuery<any, any>(courseMultiKeyQuery, {
    variables: {
      scenarioId: scenario,
      originId: origin,
      id: linkId,
    },
  });
  const defaultCourse: Course = data?.cube?.link?.course || null;
  const defaultTerm: any = data?.cube?.link.bundle.term || null;

  const errorArray = validationArray
    ? validationArray.filter((error: IObjValidationError) => error.type === "error")
    : [];
  const errorStandardArray = validationStandardArray.filter(
    error => error && error.type === "ERROR",
  );
  const warningArray = validationArray
    ? validationArray.filter((validation: IObjValidationError) => validation.type === "warning")
    : [];
  const warningStandardArray = validationStandardArray.filter(
    error => error && error.type === "WARNING",
  );

  const addGroup = async (commit: boolean) => {
    setLoading(true);
    try {
      const sharedPackComponent = componentsPayload?.length
        ? componentsPayload?.map(({ component, label }) => ({
            label,
            component,
          }))
        : undefined;

      const objMutation = {
        scenarioId: parseInt(scenario),
        originId: parseInt(origin),
        input: {
          linkId: parseInt(linkId),
          programId: parseInt(objGroup.idProgram),
          courseId: parseInt(objGroup.idCourse),
          termId: parseInt(idTerm),
          code: valueCode,
          sharedPackComponent,
          capacity: parseInt(valueCapacity),
          dryRun: !commit,
          skipValidations: commit,
        },
      };
      if (!commit) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await callMutationHook(objMutation);
      } else {
        const data = await addGroupsMutation({ variables: objMutation });
        const dataMutation: GroupPayload = data.data.cube.createGroup;
        if (dataMutation.commited) {
          onClose();
          window.location.reload();
        }

        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const callMutationHook = async (variables: any) => {
    try {
      const data = await addGroupsMutation({ variables });
      const dataMutation: CubeMutation = data.data.cube;
      const validationErrors: Array<GroupValidationErrorUnion> =
        dataMutation.createGroup.validationErrors;
      let errorsValidations = [];
      const errorStandard: IErrorWarning[] = [];
      if (validationErrors && validationErrors.length > 0) {
        validationErrors.forEach((error: GroupValidationErrorUnion) => {
          // new standard for validations (errors and warnings)
          const groupError: Group = {
            id: undefined,
            name: valueCode,
          };
          const errors = new GroupCreate(error, groupError);
          errorStandard.push(errors.getMessage());

          // old validations
          if (error && error.__typename === "InvalidFieldValidation") {
            const errorObj = getValidationErrorReason(error.reason);
            errorsValidations.push(errorObj);
          } else if (error && error.__typename === "InvalidLinkChanges") {
            const errorLinks = linkValidation(error);
            errorsValidations = [...errorsValidations, ...errorLinks];
          } else if (error && error?.__typename === "InvalidGroupCodeValidation") {
            const errors = [
              ...errorsValidations,
              {
                type: "error",
                text: "Ingresa números enteros del 1 al 999",
              },
            ];

            if (String(valueCode).startsWith("0")) {
              errors.push({
                type: "error",
                text: "El número no puede ser antecedido por uno o más ceros (01, 001)",
              });
            }

            errorsValidations = errors;
          } else {
            console.error(error && error.__typename);
          }
        });
        setValidationArray(errorsValidations);
        setvalidationStandardArray(errorStandard);
        setLoading(false);
      } else {
        addGroup(true);
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const handleCourseMultiKeyCallback = (newObjGroup: IAddGroupReturn) => {
    setObjGroup(newObjGroup || emptyObjGroup);
  };

  useEffect(() => {
    if (objGroup?.idCourse) {
      getSharedPacks(linkId, bundleId as string, objGroup?.idCourse);
    }
  }, [objGroup]);

  const hasCodeOrSharedPacks =
    (!sharedPackComponents?.length && valueCode) ||
    (!!sharedPackComponents?.length && isComponentsFormValid);

  const isSubmitActive =
    objGroup.idCampus &&
    objGroup.idDepartment &&
    objGroup.idModality &&
    objGroup.idShift &&
    objGroup.idProgram &&
    objGroup.idCurriculum &&
    objGroup.idCourse &&
    idTerm &&
    valueCapacity &&
    hasCodeOrSharedPacks &&
    errorArray.length === 0;

  /**
   * set term filter
   * termId {
   *    eq
   * }
   */
  const setTerm = (values: any) => {
    if (values.term) {
      setIdTerm(values.term);
    }
  };

  const handleChangeComponents = (payload: AdaptedSharedPackComponentPayload) => {
    setValueCode(payload?.groupCode);
    setComponentsPayload(payload?.sharedPackComponent);
  };

  const isWarningError = validationArray?.length > 0 || validationStandardArray?.length > 0;
  const isWarning = warningArray?.length > 0 || warningStandardArray?.length > 0;

  return (
    <article className={cx(css.addGroups)}>
      {(loading || isLoading) && <Loading />}
      <Header topTitle={headerData.subTitle} title={`Crear grupo | ${headerData.title} `} />
      <section className={cx(css.contentAddGroup, "container-row")}>
        <div className={cx(css.contentAddGroup_item, "container-row")}>
          <div className="col_4">
            <CourseMultiKey
              label
              className={css.contentAddGroup_item_selectors}
              defaultCourse={defaultCourse}
              onCallback={handleCourseMultiKeyCallback}
            />
          </div>
        </div>
        <div className={cx(css.contentAddGroup_item, "container-row")}>
          <div className="col_4">
            <TermSelect
              {...props}
              defaultTerm={defaultTerm}
              sendValues={(values: any) => setTerm(values)}
            />
          </div>
        </div>
        <div className={cx(css.contentAddGroup_item, "container-row")}>
          <label className={cx(css.itemLabel, "col_12")}>Vacantes</label>
          <Input
            className="col_2"
            placeholder="Número vacantes"
            type="number"
            value={valueCapacity}
            onChange={event => {
              setValueCapacity(parseInt(event.target.value));
              setValidationArray([]);
              setvalidationStandardArray([]);
            }}
          />
        </div>

        {!sharedPackComponents?.length ? (
          <div className={cx(css.contentAddGroup_item, "container-row")}>
            <Input
              label="Grupo"
              error={formatError}
              className="col_2"
              placeholder="Código de Grupo"
              value={valueCode}
              onBlur={event => {
                if (event.target.value) {
                  const pattern = /^[A-Z0-9\\d._-]*$/g;
                  if (!pattern.test(event.target.value)) {
                    setFormatError(true);
                    const message =
                      "La etiqueta del grupo debe contener únicamente caracteres alfanuméricos, puntos, guiones altos y guiones bajos.";
                    const errorStandard: IErrorWarning[] = [];
                    errorStandard.push({
                      type: "ERROR",
                      message,
                    });
                    setvalidationStandardArray(errorStandard);
                  } else {
                    setFormatError(false);
                  }
                } else {
                  setFormatError(false);
                }
              }}
              onChange={event => {
                if (event.target.value.length <= 20) {
                  setValueCode(event.target.value.toLocaleUpperCase().replace(/\s/g, ""));
                  setValidationArray([]);
                  setvalidationStandardArray([]);
                  setFormatError(false);
                }
              }}
            />
          </div>
        ) : (
          <GroupComponentInputs
            sharedPackComponents={sharedPackComponents}
            onChangeComponents={handleChangeComponents}
            onFormValid={setIsComponentsFormValid}
          />
        )}

        {isWarningError && (
          <>
            <ul className={cx(css.list_validation, "container-row")}>
              {warningArray.map((validation: any, iValidation: number) => (
                <li key={iValidation} className={cx(css.list_validation_item, "col_12")}>
                  {validation.text}
                </li>
              ))}
              {warningStandardArray.map((validation, index) => (
                <li key={index} className={cx(css.list_validation_item, "col_12")}>
                  {validation.message}
                </li>
              ))}
              {errorArray.map((validation: any, iValidation: number) => (
                <li
                  key={iValidation}
                  className={cx(
                    css.list_validation_item,
                    css.list_validation_item__error,
                    "col_12",
                  )}
                >
                  {validation.text}
                </li>
              ))}
              {errorStandardArray.map((validation, index) => (
                <li
                  key={index}
                  className={cx(
                    css.list_validation_item,
                    css.list_validation_item__error,
                    "col_12",
                  )}
                >
                  {validation.message}
                </li>
              ))}
            </ul>
            {isWarning && errorArray?.length === 0 && (
              <Checkbox
                labelRight="Ignorar advertencias"
                checked={check}
                onChange={event => setCheck(event.currentTarget.checked)}
              />
            )}
          </>
        )}
      </section>

      <footer className={cx(css.footer, "container-row")}>
        <Button typeButton="transparent" className={cx(css.footer_btn)} onClick={() => onClose()}>
          Cancelar
        </Button>
        {warningArray.length === 0 && (
          <Button
            className={cx(css.footer_btn)}
            onClick={() => addGroup(false)}
            disabled={!isSubmitActive}
          >
            Agregar
          </Button>
        )}
        {warningArray.length > 0 && (
          <Button
            className={cx(css.footer_btn)}
            onClick={() => addGroup(true)}
            disabled={!isSubmitActive || (warningArray.length > 0 && !check)}
          >
            Confirmar
          </Button>
        )}
      </footer>
    </article>
  );
};

export default AddGroups;
