import React, { useMemo, useState } from "react";
import { useMutation } from "react-apollo";
import { useParams, useHistory } from "react-router-dom";
import { Loading } from "@foris/avocado-ui";
import { IParams } from "../../../../models/IParams";
import { EmptyResult } from "../../../shared/modals";
import { PackageFilterInput } from "../../../../models/ISchema";
import Button from "../../../../common/components/Button/Button";
import { packageSearchMutation } from "../graphql/advanceSearch.queries";
import AdvancedSelectorsWrapper from "@modules/advance-search/components/advanced-selectors-wrapper";
import css from "../../../advance-search/advance-search.module.scss";

const AdvanceSearchPackage: React.FC = () => {
  const history = useHistory();
  const { origin, scenario, workspace }: IParams = useParams();
  const [advanceSearchMutation] = useMutation(packageSearchMutation, {});
  const [error, setError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectorsState, setSelectorsState] = useState({
    campus: null,
    department: null,
    modality: null,
    shift: null,
    program: null,
    curriculum: null,
    level: null,
  });

  const hasSomeFilter = useMemo(() => {
    return Object.values(selectorsState).some(value => !!value);
  }, [selectorsState]);

  const createObjectMutation = () => {
    const filterArray = [];

    selectorsState.level && filterArray.push({ level: { eq: selectorsState.level } });
    selectorsState.curriculum &&
      filterArray.push({ curriculum: { eq: selectorsState.curriculum } });
    selectorsState.campus && filterArray.push({ campusId: { eq: selectorsState.campus } });
    selectorsState.modality && filterArray.push({ modalityId: { eq: selectorsState.modality } });
    selectorsState.department &&
      filterArray.push({ departmentId: { eq: selectorsState.department } });
    selectorsState.shift && filterArray.push({ shiftId: { eq: selectorsState.shift } });
    selectorsState.program && filterArray.push({ programId: { eq: selectorsState.program } });

    const filter: PackageFilterInput = {
      fields: {
        population: {
          and: filterArray,
        },
      },
    };

    const variables = {
      originId: origin,
      scenarioId: scenario,
      filter,
      pagination: {
        page: 1,
        size: 10,
      },
    };
    return variables;
  };

  const callUpdateHook = async () => {
    setLoading(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      setLoading(false);
      const dataItems = data.filter.createPackageFilter;
      if (dataItems.packages.items.length > 0) {
        const idSearch = dataItems.id;
        const context = `${workspace}/${scenario}/${origin}`;
        const urlSearch = `/scheduler/editor/searchBy/package/${context}/?advance=${idSearch}`;
        history.push(urlSearch);
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setError(true);
    }
  };

  return (
    <>
      <EmptyResult show={isEmpty} onClose={() => setIsEmpty(false)} />

      <AdvancedSelectorsWrapper
        className={css.hasBottomSeparation}
        scenario={scenario}
        selectors={["campus", "department", "modality", "shift", "program", "curriculum", "level"]}
        useCurriculumCode
        onChangeState={setSelectorsState}
      />

      {loading ? (
        <div className="loading_wrapper">
          <Loading />
        </div>
      ) : (
        <Button
          className={css.actionButton}
          onClick={() => callUpdateHook()}
          disabled={error || !hasSomeFilter}
        >
          Buscar
        </Button>
      )}
    </>
  );
};

export default AdvanceSearchPackage;
