import gql from "graphql-tag";

export const curriculumQuery = gql`
  query curriculum($scenarioId: ID!, $filter: CurriculumFilterInput) {
    data(scenarioId: $scenarioId) {
      curriculums(filter: $filter) {
        items {
          id
          code
          name
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
